import React from 'react';
// import {DefaultPlayer as Video} from 'react-html5video';
import {Carousel} from 'react-bootstrap';
import testVideo from './videos/testvideo.mp4';
import ReactPlayer from 'react-player';
// import 'react-html5video/dist/styles.css';
import 'bootstrap/dist/css/bootstrap.css';
import './VideoCarousel.css';



const ReactVideoPlayer = () => {

  const videoProperties = [

    {
      id: 1,
      src: testVideo,
    }
  ]
  
    return (

      <Carousel>
        {
          videoProperties.map((videoObj) => {
            return (
              <Carousel.Item key ={videoObj.id}>
                <ReactPlayer
                  url ={videoObj.src}
                  width= '100%'
                  pip = {true}
                  controls = {true}
                  playing={true}
                
                />

                

              </Carousel.Item>
            )
          })
        }

      </Carousel>
      // <Video autoPlay loop>
      //   <source  src={testVideo} type="video/webm"/>
      // </Video>
    );
  
}

export default ReactVideoPlayer;