import './App.css';
import { ethers } from 'ethers';
import { useEffect, useState,useLayoutEffect } from 'react';
import { SuitHeartFill } from 'react-bootstrap-icons';
import abi from './abi.json';
import ReactVideoPlayer from './ReactVideoPlayer' ;

import Modal from 'react-bootstrap/Modal';
import PictureCarousel from './pictureCarousel' ;
import Button from 'react-bootstrap/Button';
function App() {

  const [provider, setProvider] = useState(undefined);
  const [signer, setSigner] = useState(undefined);
  const [contract, setContract] = useState(undefined);
  const [amount, setAmount] = useState('0');
  const [donations, setDonations] = useState([]);
  const [showModalConnect, setShowModalConnect] = useState(false);
  const [showModalAmount, setShowModalAmount] = useState(false);
  const [showModalNotEnoughBalance, setShowModalNotEnoughBalance] = useState(false);
  const [balance, setBalance] = useState(null);
  const [metamaskWarning, setMetamaskWarning] = useState(false);

  const toString = bytes32 => ethers.utils.parseBytes32String(bytes32);
  const toWei = ether => ethers.utils.parseEther(ether);
  const toEther = wei => ethers.utils.formatEther(wei).toString();

  useEffect(() => {

    if (window.ethereum) {

      window.ethereum.on('accountsChanged', getBalance);

      }


    const init = async () => {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      setProvider(provider)

      const contract = new ethers.Contract(
        '0x3E935164aE166bdE8Fc2a66e28b5F390B15C7A82',
        abi
      )
      setContract(contract)

      contract.connect(provider).getDonations()
        .then((result) => {
          const donations = result.map(el => [el[0], toEther(el[1])])
          setDonations(donations)
        })
    }
    init();
  }, [])

  const isConnected = () => (signer !== undefined)

  const getSigner = async provider => {
    provider.send("eth_requestAccounts", []).then(
      result =>{getBalance(result[0].toString())}
    );
    const signer = await provider.getSigner();
    setSigner(signer)
  }

  const getBalance = (address) => {
    if (address.length === 0) {
      setSigner(undefined)

      return
  }
    window.ethereum.request({method: 'eth_getBalance', params: [address.toString(), 'latest']}).then(
      balance =>{
        setBalance(ethers.utils.formatEther(balance))
        console.log(ethers.utils.formatEther(balance))
      }
      
    )
  }

  const connect = () => {
    if (window.ethereum) {
      getSigner(provider)

  } else {
      setMetamaskWarning(true)
      
  }
    
  }

  const sendDonation = async () => {
    
    if (signer === undefined) {
      console.log('not logged in')
      setShowModalConnect(true)
      return
    }
    if (amount === '0') {
      console.log('please select an amount')
      setShowModalAmount(true)
      return
    }
    if (parseFloat(amount) > balance) {
      console.log(' You do not have enough balance in your wallet')
      setShowModalNotEnoughBalance(true)
      return
    }

    const wei = toWei(amount)

    await signer.sendTransaction({
      to: contract.address,
      value: wei
    })

    setAmount('0')
  }

  // window.ethereum.on('accountsChanged', getBalance);

  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <div className="row" style={{width: '800px'}}>

          <div className="col-md-4">
            <div className="row">
              <div className="col-md-12">
                <h1 className="donateHeader">Donate BNB</h1>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 amountButtonLeft">
                <a
                onClick={ () => setAmount('0.01') }
                className={"amountButton " + (amount === '0.01' ? 'amountClicked' : '')}>
                  0.01
                </a>
              </div>
              <div className="col-md-6 amountButtonRight">
                <a
                onClick={ () => setAmount('0.05') }
                className={"amountButton " + (amount === '0.05' ? 'amountClicked' : '')}>
                  0.05
                </a>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 amountButtonLeft">
                <a
                onClick={ () => setAmount('0.5') }
                className={"amountButton " + (amount === '0.5' ? 'amountClicked' : '')}>
                  0.5
                </a>
              </div>
              <div className="col-md-6 amountButtonRight">
                <a
                onClick={ () => setAmount('5') }
                className={"amountButton " + (amount === '5' ? 'amountClicked' : '')}>
                  5
                </a>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <a
                onClick={ () => sendDonation() }
                className="amountButton">Donate</a>
              </div>
            </div>

            <Modal show={showModalConnect}>
                    <Modal.Header flex-direction="column" >
                        Please connect first.
                        
                        <Button variant="primary" onClick={() => setShowModalConnect(false)}> OK </Button>
                    </Modal.Header>
            </Modal>

            <Modal show={showModalAmount}>
                    <Modal.Header flex-direction="column" >
                        Please select an amount to donate.
                        
                        <Button variant="primary" onClick={() => setShowModalAmount(false)}> OK </Button>
                    </Modal.Header>
            </Modal>

            <Modal show={showModalNotEnoughBalance}>
                    <Modal.Header flex-direction="column" >
                    You do not have enough balance in your wallet.
                        
                        <Button variant="primary" onClick={() => setShowModalNotEnoughBalance(false)}> OK </Button>
                    </Modal.Header>
            </Modal>

            <Modal show={metamaskWarning}>
                    <Modal.Header flex-direction="column" style={{ fontWeight: "bold" }}>
                        Need to install Metamask!<br></br><br></br>
                        
                        If you are using a mobile device, download Metamask from App Store/Play Store and open this page again in Metamask app browser.
                        <Button variant="primary" onClick={() => setMetamaskWarning(false)}>OK</Button>
                    </Modal.Header>
                </Modal>

            <div className="row">
              <div className="col-md-12">
                {isConnected() ? (
                  <>
                    <span className="dot greenDot"></span>
                    <p style={{fontSize: '25px'}}>Connected</p>
                  </>
                ) : (
                  <>
                    <span className="dot redDot"></span>
                    <p style={{fontSize: '25px'}}>Not connected</p>
                    <button onClick={connect} className="btn btn-primary">Connect Wallet</button>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-2">
          </div>

          <div className="col-md-6">

            <div className="row">
              <div className="col-md-12">
                <h1 className="donateHeader2">Save these dogs!</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h1 className="donateHeader">Recent Donations</h1>
              </div>
            </div>
            {donations.map((ds,idx) => (
              <>
                <div className="donationBubbleLeft">
                  <SuitHeartFill fill="#FF7F97" />
                  <span className="paddingLeft">
                    {ds[1]} BNB
                    &nbsp;
                    <span className="byAddress">by {ds[0]?.substring(0,14)}...</span>
                  </span>
                </div>

              </>
            ))}
          </div>
        </div>

        <br></br>
        <br></br>
        <h2>Save these stray dogs!!</h2>
        <p className='col-md-5'>We run a dog farm which houses all the stray dogs we save! Most of them are suffering from different kinds of diseases and disabilities which need immediate medical care. We hope all the pet lovers can make donations so that these little pets can receive medical treatment and surgery right away. Thanks for your kindness.</p>
        
        <div className='col-md-8'>
            <PictureCarousel/>
        </div>
        <p className='col-md-5'>This dog has one eye blinded and a big would in its neck when we found it. It was taken to the VET right away but the surgery fee was huge. We are running out of funds but we have a lot of dogs on line waiting for surgeries. Please help them!</p>
        <div className='col-md-8'>
            <ReactVideoPlayer/>
        </div>
      </header>
      
    </div>
  );
}

export default App;
