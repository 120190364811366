import React from 'react';

import {Carousel} from 'react-bootstrap';
import dog1 from './pictures/dog1.jpeg';
import dog2 from './pictures/dog2.jpeg';
import dog3 from './pictures/dog3.jpeg';
// import 'bootstrap/dist/css/bootstrap.css';
import './PictureCarousel.css';



const PictureCarousel = () => {

  const pictureProperties = [

    {
      id: 1,
      src: dog1,
    },
    // {
    //     id: 2,
    //     src: dog2,
    //   },
      {
        id: 3,
        src: dog3,
      }
  ]
  
    return (

      <Carousel>
        {
          pictureProperties.map((Obj) => {
            return (
              <Carousel.Item key ={Obj.id}>
                <img
                src ={Obj.src}
                alt=''
                />
                
                </Carousel.Item>
            )
          })
        }

      </Carousel>
      // <Video autoPlay loop>
      //   <source  src={testVideo} type="video/webm"/>
      // </Video>
    );
  
}

export default PictureCarousel;